<template>
  <div>
    <template v-if="loading">
      <loading>Updating User Authentication...</loading>
    </template>
    <template v-else>
      <div class="columns">
        <div class="column is-6 is-offset-3">
          <ValidationObserver ref="observer" v-slot="{ passes }">
            <form @submit.prevent="passes(add)">
              <SdInput
                type="text"
                rules="required"
                label="Name"
                v-model="user.name"
              />
              <SdInput
                type="text"
                rules="required"
                label="Email"
                v-model="user.email"
              />
              <button
                type="submit"
                class="button btn-120 is-primary is-capitalized is-pulled-right mt-3"
              >
                Submit
              </button>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import SdInput from '../../components/SdInput'
import { ValidationObserver } from 'vee-validate'
import { crudNotification } from '../../assets/js/notification'
import { getFunctions, httpsCallable } from 'firebase/functions'

export default {
  name: 'UserAuthentication',
  data() {
    return {
      schoolId: null,
      pageTitle: 'User Authentication',
      user: {},
      loading: false,
    }
  },
  methods: {
    add() {
      this.loading = true
      const functions = getFunctions()
      const createUser = httpsCallable(functions, 'createUser')

      createUser(this.user)
        .then((result) => {
          this.updateFirebaseUserId(result.data.user.uid)
        })
        .catch((error) => {
          this.$buefy.toast.open({
            duration: 5000,
            message: error.message,
            position: 'is-bottom',
            type: 'is-danger',
          })
          this.loading = false
        })
    },
    updateFirebaseUserId(uid) {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation UpdateFirebaseUserIdQuery(
              $id: Int!
              $email: String!
              $firebaseUserId: String!
              $context: String!
            ) {
              updateFirebaseUserId(
                input: {
                  id: $id
                  email: $email
                  firebaseUserId: $firebaseUserId
                  context: $context
                }
              ) {
                user {
                  id
                }
                errors
              }
            }
          `,
          variables: {
            id: parseInt(this.user.id),
            email: this.user.email,
            firebaseUserId: uid,
            context: this.user.context,
          },
        })
        .then((response) => {
          crudNotification(
            response.data.updateFirebaseUserId.errors,
            'Successfully updated.'
          ).then(() => {
            if (this.user.context === 'Educator') {
              this.$router.push(
                `/school/${this.schoolId}/educator_info/${this.user.id}`
              )
            } else if (this.user.context === 'Student') {
              this.$router.push(
                `/school/${this.schoolId}/student_info/${this.user.id}`
              )
            }
            this.loading = false
          })
        })
        .catch((error) => {
          // console.error(error);
        })
    },
  },
  components: {
    SdInput,
    ValidationObserver,
  },
  mounted() {
    if (this.$route.params.id !== 'new') this.skipQuery = false
    this.schoolId = parseInt(this.$route.params.school_id)
    this.$store.commit('setPageTitle', this.pageTitle)
    this.$store.commit('setSubMenus', [
      {
        name: 'Dashboard',
        route: `/school/${this.$route.params.school_id}/dashboard`,
      },
    ])

    const data = this.$store.getters.getRecord

    if (data.hasOwnProperty('context')) {
      this.user = data
    } else {
      this.$router.push(`/school/${this.$route.params.school_id}/dashboard`)
    }
  },
}
</script>
